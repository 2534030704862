import { FC, memo, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { Strings } from '~/data/types'

import useClickAway from '~/hooks/use-click-away'

import BaseButton from '~/components/BaseButton/BaseButton'

import css from '#/css/components/Player/Player.module.css'

import CheckMark from '#/svgs/CheckMark'
import Chevron from '#/svgs/Chevron'

export interface PlayerProps {
  className?: string
  onLoad?: () => void
  subTitle: string
  region: string
  players: Strings['pageLanding']['body']['liveStream']['players']
}

export type RegionKey = keyof PlayerProps['players']

export interface ViewProps extends PlayerProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ className, onLoad, subTitle, players, region }) => {
  const toggleRef = useRef<HTMLDivElement>(null)

  const [langs, setLangs] = useState<{ title: string; player: string }[]>([])
  const [currentLang, setCurrentLang] = useState<number>(0)
  const [toggleLang, setToggleLang] = useState<boolean>(false)

  useEffect(() => {
    const selectedRegion = players[region as RegionKey]

    setLangs(selectedRegion)
  }, [players, region])

  useClickAway(toggleRef, () => {
    setToggleLang(false)
  })

  return (
    <div className={classNames('Player', css.root, className)}>
      {langs.length && (
        <iframe
          title="live player"
          className={css.player}
          src={langs[currentLang].player}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          onLoad={() => onLoad?.()}
        />
      )}

      {langs.length > 1 && (
        <div ref={toggleRef} className={classNames(css.langs, { [css.open]: toggleLang })}>
          <BaseButton onClick={() => setToggleLang(!toggleLang)} className={css.title} aria-label={subTitle}>
            <span className={css.text}>{langs[currentLang].title === 'Off' ? subTitle : langs[currentLang].title}</span>
            <Chevron className={css.icon} />
          </BaseButton>
          <div className={css.list}>
            {langs.map((lang, index) => (
              <BaseButton
                className={classNames(css.item, { [css.active]: currentLang === index })}
                key={index}
                onClick={() => {
                  setCurrentLang(index)
                  setToggleLang(false)
                }}
                aria-label={lang.title}
              >
                {lang.title}
                <CheckMark className={css.icon} />
              </BaseButton>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

View.displayName = 'Player-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const Player: FC<PlayerProps> = (props) => {
  return <View {...props} />
}

export default memo(Player)
