import config from '~/data/config.json'

type LocalStorageListener = (name: string, value: string | undefined) => void

class Service {
  listeners: LocalStorageListener[] = []

  set = (name: string, value: string): boolean => {
    try {
      if (value !== undefined) {
        const serializedData = localStorage.getItem(config.storageName)
        if (serializedData === null) {
          localStorage.setItem(config.storageName, JSON.stringify({ [name]: value }))
        } else {
          localStorage.setItem(config.storageName, JSON.stringify({ ...JSON.parse(serializedData), [name]: value }))
        }
      }
      this.listeners.forEach((listener) => listener(name, value))
      return true
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return false
    }
  }

  get = (name: string): string | undefined => {
    try {
      const serializedData = localStorage.getItem(config.storageName)
      if (!serializedData) return undefined
      return JSON.parse(serializedData)[name] || undefined
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return undefined
    }
  }

  listen = (listener: LocalStorageListener) => {
    if (!this.listeners.includes(listener)) this.listeners.push(listener)
  }

  dismiss = (listener: LocalStorageListener) => {
    this.listeners = this.listeners.filter((l) => l !== listener)
  }

  clear = () => {
    this.listeners = []
    localStorage.clear()
  }
}
const LocalStorageService = new Service()

export default LocalStorageService
