import { FC, FormEvent, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useOutletContext } from '@remix-run/react'
import classNames from 'classnames'

import { gsap } from '~/imports/gsap.client'

import { PageHandle, PageProps, Strings } from '~/data/types'

import copy from '~/utils/copy'

import css from '#/css/components/PageGate/PageGate.module.css'

import ArrowLeft from '#/svgs/ArrowLeft'
import ShopifyPartnersLogo from '#/svgs/ShopifyPartnersLogo'

import BaseButton from '../BaseButton/BaseButton'

export interface PageGateProps extends PageProps {
  className?: string
  onAccept: () => void
  common: Strings['common']
  region: string
}

export interface ViewProps extends PageGateProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ common, region, onAccept, onReady }) => {
  const formData = common.emailScreen.emailForm

  const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined)

  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleRef = useRef<PageHandle>(null)

  useEffect(() => {
    onReady?.(handleRef)
  }, [onReady])

  useImperativeHandle(handleRef, () => ({
    animateIn: () => gsap.timeline().to(rootRef.current, { opacity: 1 }),
    animateOut: () => gsap.timeline().to(rootRef.current, { opacity: 0 })
  }))

  const submitForm = useCallback(
    (event: FormEvent) => {
      event.preventDefault()

      const isValid = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputRef.current!.value)

      if (isValid) {
        setErrorMessage(undefined)
      } else {
        setErrorMessage('Invalid email address')
      }

      if (!isValid || !inputRef.current || (inputRef.current && inputRef.current!.value === '')) {
        return
      }

      const timestamp = `${Date.now()}`

      fetch('https://monorail-edge.shopifysvc.com/v1/produce', {
        body: JSON.stringify({
          schema_id: 'partners_education_day_event_watch/1.0',
          payload: {
            email: inputRef.current!.value,
            region
          }
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-Monorail-Edge-Event-Created-At-Ms': timestamp,
          'X-Monorail-Edge-Event-Sent-At-Ms': timestamp
        },
        method: 'POST'
      })
        .then((success) => {
          if (success.status === 200) {
            onAccept()
          } else {
            setErrorMessage('Server error')
          }
        })
        .catch((err) => {
          console.error(err)
          setErrorMessage('Server error')
        })
    },
    [onAccept, region]
  )

  return (
    <div className={classNames('PageGate', css.root)} ref={rootRef}>
      <ShopifyPartnersLogo className={css.logo} />

      <div className={css.content}>
        <h1 className={css.title} {...copy.html(common.emailScreen.title)} />
        <form
          onSubmit={submitForm}
          method="post"
          className={classNames(css.form, {
            [css.invalid]: errorMessage !== undefined
          })}
        >
          <div className={css.input}>
            <input ref={inputRef} type="text" id={formData.email.id} name={formData.email.id} />
            <label htmlFor={formData.email.id}>{errorMessage || formData.email.label}</label>
          </div>
          <BaseButton className={css.button}>
            <span className={css.buttonContent}>
              {formData.cta.label}
              <ArrowLeft className={css.icon} />
            </span>
          </BaseButton>
          <p className={css.cookieMsg} {...copy.html(common.cookie)} />
        </form>
      </div>
    </div>
  )
}

View.displayName = 'PageGate-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const PageGate: FC<PageGateProps> = (props) => {
  const context = useOutletContext() as PageProps
  return <View {...props} {...context} />
}

export default memo(PageGate)
