import { forwardRef, ImgHTMLAttributes, memo, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

import { noop } from '~/utils/basic-functions'
import getOptimizedUrl, { OptimizationOptions } from '~/utils/get-optimized-url'

import useCombinedRefs from '~/hooks/use-combined-refs'

import css from '#/css/components/BaseImage/BaseImage.module.css'

export interface BaseImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  options?: OptimizationOptions
  srcWidths?: number[]
  fetchpriority?: 'high' | 'low' | 'auto'
  fileDimensions?: { width: number; height?: number }
  skipOptimization?: boolean
  allowRetina?: boolean
  onLoad?: () => void
}

const BaseImage = forwardRef<HTMLImageElement, BaseImageProps>(
  (
    {
      src,
      style,
      options,
      className,
      fileDimensions,
      alt = '',
      decoding = 'async',
      srcWidths,
      skipOptimization = false,
      allowRetina = true,
      onLoad = noop,
      ...props
    },
    ref
  ) => {
    const [loaded, setLoaded] = useState(false)
    const [size, setSize] = useState<string>('1px')

    const rootRef = useRef<HTMLImageElement>(null)
    const combinedRef = useCombinedRefs(ref, rootRef)

    const optimize = useMemo(
      () => !skipOptimization, // && imgSrc.startsWith('/') && !imgSrc.toLowerCase().endsWith('gif'),
      [skipOptimization]
    )

    const imgSrcWidths = useMemo(() => {
      const base = 128
      if (!src || !fileDimensions?.width) return []
      if (srcWidths) return srcWidths?.filter((w) => w <= fileDimensions.width)
      if (fileDimensions.width < base) return []
      const hops = Math.floor(fileDimensions.width / base)
      const sizes = [...Array(hops)].map((_, i) => (i + 1) * base)
      return Array.from(new Set([fileDimensions.width, ...sizes])).sort((a, b) => (a > b ? 1 : -1))
    }, [src, fileDimensions, srcWidths])

    const optimizedSrc = useMemo(() => {
      return optimize ? getOptimizedUrl(src, options) : src
    }, [src, optimize, options])

    const optimizedSrcSet = useMemo(() => {
      if (!optimize || options?.resize || imgSrcWidths.length < 2) return undefined
      const opt = (options || {}) as OptimizationOptions
      return imgSrcWidths
        .map((w) => {
          const o = { ...opt, resize: { ...opt.resize, width: w } }
          const url = getOptimizedUrl(src, o)
          return `${url} ${w}w`
        })
        .join(', ')
    }, [optimize, imgSrcWidths, options, src])

    useEffect(() => {
      const root = rootRef.current!
      let observer: ResizeObserver
      if (imgSrcWidths.length && window.ResizeObserver) {
        observer = new ResizeObserver(() => {
          const elSize = root.clientWidth
          const curSize = imgSrcWidths.find((s) => s >= elSize)
          if (curSize) setSize(`${curSize / (allowRetina ? 1 : window.devicePixelRatio || 1)}px`)
        })
        observer.observe(root)
      }
      return () => {
        observer?.unobserve(root)
      }
    }, [imgSrcWidths, allowRetina])

    useEffect(() => {
      const img = rootRef.current!
      const handleLoad = () => {
        img.removeEventListener('load', handleLoad)
        img.removeEventListener('loadedmetadata', handleLoad)
        setLoaded(true)
        onLoad()
        ScrollTrigger.refresh()
      }
      if (!loaded && optimizedSrc) {
        const loaded = Boolean(img.complete)
        if (loaded) {
          setLoaded(true)
          onLoad()
        } else {
          img.addEventListener('load', handleLoad)
          img.addEventListener('loadedmetadata', handleLoad)
        }
      }
      return () => {
        img.removeEventListener('load', handleLoad)
        img.removeEventListener('loadedmetadata', handleLoad)
      }
    }, [optimizedSrc, loaded, onLoad])

    return (
      <img
        className={classNames('BaseImage', css.root, className)}
        decoding={decoding}
        srcSet={optimizedSrcSet}
        style={style}
        src={optimizedSrc}
        ref={combinedRef}
        alt={alt}
        sizes={imgSrcWidths.length ? size : undefined}
        {...(fileDimensions?.width && fileDimensions?.height
          ? { width: `${fileDimensions.width}px`, height: `${fileDimensions.height}px` }
          : {})}
        {...props}
      />
    )
  }
)

BaseImage.displayName = 'BaseImage'

export default memo(BaseImage)
