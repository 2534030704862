import { FC, memo } from 'react'
import classNames from 'classnames'

import { Strings } from '~/data/types'

import copy from '~/utils/copy'

import css from '#/css/components/Header/Header.module.css'

import ArrowDown from '#/svgs/ArrowDown'
import ShopifyPartnersLogo from '#/svgs/ShopifyPartnersLogo'

export interface HeaderProps {
  strings: Strings['common']['navbar']
}

export interface ViewProps extends HeaderProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ strings }) => {
  return (
    <header className={classNames('Header', css.root)}>
      <ShopifyPartnersLogo />
      <div className={css.welcome}>
        <p className={css.title} {...copy.html(strings.title)} />
        <p className={css.description} {...copy.html(strings.description)} />
      </div>
      <a className={css.cta} href={strings.cta.link}>
        <span {...copy.html(strings.cta.label)} />
        <span className={css.icon}>
          <ArrowDown />
        </span>
      </a>
    </header>
  )
}

View.displayName = 'Header-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const Header: FC<HeaderProps> = (props) => {
  return <View {...props} />
}

export default memo(Header)
