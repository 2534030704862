import { FC, memo } from 'react'
import classNames from 'classnames'

import { Strings } from '~/data/types'

import Player, { PlayerProps } from '~/components/Player/Player'

import css from '#/css/components/LiveStream/LiveStream.module.css'

export interface LiveStreamProps {
  className?: string
  chats: Strings['pageLanding']['body']['liveStream']['chats']
  region: string
  players: PlayerProps['players']
  subTitle: PlayerProps['subTitle']
}

export interface ViewProps extends LiveStreamProps {}

export type RegionKey = keyof ViewProps['chats']

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ className, players, chats, subTitle, region }) => {
  return (
    <div className={classNames('LiveStream', css.root, className)}>
      <div className={css.player}>
        <Player subTitle={subTitle} players={players} region={region} />
      </div>
      <div className={css.chat}>
        <iframe className={css.chatEmbed} title="live chat" src={chats[region as RegionKey]} />
      </div>
    </div>
  )
}

View.displayName = 'LiveStream-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const LiveStream: FC<LiveStreamProps> = (props) => {
  return <View {...props} />
}

export default memo(LiveStream)
