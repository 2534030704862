export type OptimizationOptions = {
  resize?: {
    width?: number
    height?: number
  }
}

function getOptimizedUrl(src?: string, _edits?: OptimizationOptions) {
  // TODO: Implement any service that can optimize images
  // like Cloudinary, Imgix, etc.
  return src
}

export default getOptimizedUrl
