import { SVGProps } from 'react'
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 13 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M2.013 18.854C1.35 19.252.5 18.787.5 18.027V13H4a.5.5 0 0 0 .5-.5v-1A.5.5 0 0 0 4 11H.5V5.973c0-.76.851-1.225 1.512-.827l10.016 6.028a.96.96 0 0 1 0 1.653L2.011 18.854z"
        clipRule="evenodd"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path transform="matrix(1 0 0 -1 .5 24)" fill="#fff" d="M0 0h12v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgArrowLeft
