export function noop() {}

export function hash(str: string) {
  let hash = 0
  let chr
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash)
}

export function cleanPathname(path = ''): string {
  return path.split('#')[0].split('?')[0]
}

export function formatSeconds(seconds: number): string {
  return new Date((seconds || 0) * 1000).toISOString().substr(11, 8).replace('00:00:', '0:').replace('00:', '0:')
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function deepClone(obj: unknown | string) {
  if (!obj || typeof obj === 'string') return obj
  return JSON.parse(JSON.stringify(obj))
}

export function rem(value: number): number {
  const htmlStyle = window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size')
  return parseFloat(htmlStyle) * value
}

export function capitalize(string: string): string {
  const lc = string.toLowerCase()
  return lc[0].toUpperCase() + lc.substring(1)
}

export function formatDate(date: Date | string): string {
  const formatter = new Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  })
  return formatter.format(typeof date === 'string' ? new Date(date) : date)
}

export function isOnScreen(element: HTMLElement, minVisiblePercentage = 0, topReference = 0) {
  const rect = element.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  const isCompletelyOut = rect.bottom < 0 || rect.top - viewHeight >= 0
  if (isCompletelyOut) return false
  if (minVisiblePercentage <= 0) return true
  const percentageOnScreen =
    (Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, topReference)) / rect.height
  return percentageOnScreen >= minVisiblePercentage
}

export function delay(timeout: number) {
  return new Promise<void>(function (resolve) {
    setTimeout(resolve, timeout)
  })
}

export function randomIndex<T>(array: T[]) {
  return Math.floor(Math.random() * array.length)
}

export function randomItem<T>(array: T[]) {
  return array[randomIndex(array)]
}

export function download(blob: Blob, filename: string) {
  const link = document.createElement('a')

  link.href = URL.createObjectURL(blob)
  link.download = filename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function formatNumber(price: number | string, locale = 'en-US'): string {
  return parseInt(`${price}`).toLocaleString(locale).toString()
}

export function getOrdinalNumber(n: number) {
  let ord = 'th'

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st'
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd'
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd'
  }

  return ord
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getCountdown(to: string | number) {
  const now = Date.now()
  const end = new Date(to)
  const diffTime = end.valueOf() - now.valueOf()
  // const days = diffTime < 0 ? 0 : Math.floor(diffTime / (1000 * 60 * 60 * 24));
  // const hours = diffTime < 0 ? 0 : Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = diffTime < 0 ? 0 : Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = diffTime < 0 ? 0 : Math.floor((diffTime % (1000 * 60)) / 1000)
  const timeLeftInSecs = diffTime < 0 ? 0 : Math.floor(diffTime / 1000)

  return {
    // days: String(days).padStart(2, '0'),
    // hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
    timeLeftInSecs
  }
}
