import { FC, memo } from 'react'
import classNames from 'classnames'

import BaseImage from '~/components/BaseImage/BaseImage'

import css from '#/css/components/SpeakerCard/SpeakerCard.module.css'

export interface SpeakerCardProps {
  className?: string
  speaker: { name: string; role: string; image?: string }
}

export interface ViewProps extends SpeakerCardProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ speaker, className }) => {
  return (
    <div className={classNames('SpeakerCard', css.root, className)} role="note">
      <BaseImage className={css.profileImage} src={speaker.image} />
      <div className={css.text}>
        <b>{speaker.name}</b>
        <p>{speaker.role}</p>
      </div>
    </div>
  )
}

View.displayName = 'SpeakerCard-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const SpeakerCard: FC<SpeakerCardProps> = (props) => {
  return <View {...props} />
}

export default memo(SpeakerCard)
