import { FC, memo } from 'react'
import classNames from 'classnames'

import copy from '~/utils/copy'

import BaseImage from '~/components/BaseImage/BaseImage'

import css from '#/css/components/FollowUp/FollowUp.module.css'

export interface FollowUpProps {
  className?: string
  title: string
  description: string
  cover: string
}

export interface ViewProps extends FollowUpProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ className, title, description, cover }) => {
  return (
    <div className={classNames('FollowUp', css.root, className)}>
      <div className={css.content}>
        <div className={css.copy}>
          <strong className={css.title}>{title}</strong>
          <p className={css.description} {...copy.html(description)} />
        </div>
      </div>
      <div className={css.cover}>
        <BaseImage className={css.image} src={cover} />
      </div>
    </div>
  )
}

View.displayName = 'FollowUp-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const FollowUp: FC<FollowUpProps> = (props) => {
  return <View {...props} />
}

export default memo(FollowUp)
