import { FC, memo } from 'react'
import classNames from 'classnames'

import { Strings } from '~/data/types'

import copy from '~/utils/copy'

import SpeakerCard from '~/components/SpeakerCard/SpeakerCard'

import css from '#/css/components/EventAgenda/EventAgenda.module.css'

export interface EventAgendaProps {
  className?: string
  strings: Strings['pageLanding']['body']['agenda']
}

export interface ViewProps extends EventAgendaProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ className, strings }) => {
  return (
    <div id={strings.id} className={classNames('EventAgenda', css.root, className)}>
      <h2 className={css.agendaTitle} {...copy.html(strings.title)} />
      {strings.events.map((event, keyX: number) => {
        return (
          <section className={css.wrapper} key={keyX}>
            <div className={css.event}>
              <div className={css.index}>
                <b {...copy.html(event.index)} />
              </div>
              <div className={css.info} aria-label={event.description}>
                <h3 className={css.title} {...copy.html(event.title)} />
                {event.description && <p className={css.description} {...copy.html(event.description)} />}
              </div>
            </div>
            <div className={css.speakers}>
              {event.speakers.map((speaker, keyY: number) => {
                return <SpeakerCard className={css.card} key={keyY} speaker={speaker} />
              })}
            </div>
          </section>
        )
      })}
    </div>
  )
}

View.displayName = 'EventAgenda-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const EventAgenda: FC<EventAgendaProps> = (props) => {
  return <View {...props} />
}

export default memo(EventAgenda)
