import { FC, memo, useEffect, useImperativeHandle, useRef } from 'react'
import { useOutletContext } from '@remix-run/react'
import classNames from 'classnames'

import { gsap } from '~/imports/gsap.client'

import { PageHandle, PageProps, Strings } from '~/data/types'

import FollowUp from '~/components/FollowUp/FollowUp'
import Header from '~/components/Header/Header'
import LiveStream from '~/components/LiveStream/LiveStream'

import css from '#/css/components/PageLanding/PageLanding.module.css'

import EventAgenda from '../EventAgenda/EventAgenda'

export interface PageLandingProps extends PageProps {
  strings: Strings['pageLanding']['body']
  region: string
  common: Strings['common']
}
export interface ViewProps extends PageLandingProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ common, strings, region, onReady }) => {
  const rootRef = useRef<HTMLElement>(null)
  const handleRef = useRef<PageHandle>(null)

  useEffect(() => {
    onReady?.(handleRef)
  }, [onReady])

  useImperativeHandle(handleRef, () => ({
    animateIn: () => gsap.timeline().to(rootRef.current, { opacity: 1 }),
    animateOut: () => gsap.timeline().to(rootRef.current, { opacity: 0 })
  }))

  return (
    <main className={classNames('PageLanding', css.root)} ref={rootRef}>
      <Header strings={common.navbar} />
      <LiveStream
        chats={strings.liveStream.chats}
        region={region}
        players={strings.liveStream.players}
        subTitle={strings.liveStream.subTitle}
      />
      <EventAgenda strings={strings.agenda} />
      <FollowUp
        title={strings.followUp.title}
        description={strings.followUp.description}
        cover={strings.followUp.cover}
      />
    </main>
  )
}

View.displayName = 'PageLanding-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const PageLanding: FC<PageLandingProps> = (props) => {
  const context = useOutletContext() as PageProps
  return <View {...props} {...context} />
}

export default memo(PageLanding)
