import { useCallback, useEffect, useState } from 'react'
import { LoaderArgs, MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

import strings from '~/data/strings'

import LocalStorageService from '~/services/local-storage'

import generateHeadProps from '~/utils/generate-head-props'

import PageFallback from '~/components/PageFallback/PageFallback'
import PageGate from '~/components/PageGate/PageGate'
import PageLanding from '~/components/PageLanding/PageLanding'

export const loader = async ({ request }: LoaderArgs) => {
  // Switch to CMS data here if needed
  const url = new URL(request.url)
  const secretParam = url.searchParams.get('event')
  const region = url.searchParams.get('region')
  const validRegion =
    Object.keys(strings.pageLanding.body.liveStream.players).find((player) => {
      return player === region?.toLowerCase()
    }) || []

  return {
    region: region?.toLowerCase() ?? '',
    isValid: secretParam === '' && validRegion?.length > 0,
    common: strings.common,
    landing: strings.pageLanding,
    private: strings.pagePrivate
  }
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const { head } = data.isValid ? data.landing : data.private
  return generateHeadProps(head.title, head.description, head.keywords)
}

export default function PageLandingRoute() {
  const data = useLoaderData<typeof loader>()

  // TODO: rita/jacob/ruaan set this state to false if you want to disable the email gate <3<3
  const [showGate, setShowGate] = useState(true)

  useEffect(() => {
    const isLoggedIn = LocalStorageService.get('passGate')

    if (isLoggedIn === 'true') {
      setShowGate(false)
    }
  }, [setShowGate])

  const acceptHandle = useCallback(() => {
    LocalStorageService.set('passGate', 'true')
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' as ScrollBehavior })
    setShowGate(false)
  }, [])

  return data.isValid ? (
    showGate ? (
      <PageGate common={data.common} region={data.region} onAccept={acceptHandle} />
    ) : (
      <PageLanding common={data.common} strings={data.landing.body} region={data.region} />
    )
  ) : (
    <PageFallback strings={data.private.body} />
  )
}
